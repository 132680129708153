
import { defineComponent } from 'vue';
import { Form, Field } from 'vee-validate';
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import Swal from 'sweetalert2/dist/sweetalert2.min.js';
// import * as Yup from "yup";
import ApiService from '@/core/services/ApiService';
import { apiEndpoint } from '@/mixin/apiEndpoint';
import { VueCookieNext } from 'vue-cookie-next';
import BatchDrawer from '@/layout/header/partials/batch/BatchUpdateDrawer.vue';
import moment from 'moment';

// import * as Yup from "yup";

import axios from 'axios';
import { ElNotification } from 'element-plus';
export default defineComponent({
  mixins: [apiEndpoint],
  name: 'new-running-batch report',
  components: {
    Form,
    Field,
    Datatable,
    BatchDrawer,
  },
  data() {
    return {
      moment: '' as any,
      batch: {
        entity_id: '',
        tranche_id: '',
        institute_id: '',
        course_id: '',
      },
      entityInfos: [],
      tableHeader: [
        // {
        //   name: 'Actions',
        //   key: 'actions',
        //   sortable: false,
        //   width: '150px',
        // },
        {
          name: 'Sl',
          key: 'sl',
          sortable: true,
          width: '5px',
        },
        {
          name: 'Phase',
          key: 'tranche',
          sortable: true,
        },
        {
          name: 'Association',
          key: 'entity_name',
          sortable: true,
        },
        {
          name: 'Institute Name',
          key: 'ins_name',
          sortable: true,
          width: '220px',
        },
        {
          name: 'Division',
          key: 'division',
          sortable: true,
        },
        {
          name: 'District',
          key: 'district_name',
          sortable: true,
        },
        {
          name: 'Present Address',
          key: 'present_address',
          sortable: true,
          width: '220px',
        },
        {
          name: 'Course Name',
          key: 'course_name',
          sortable: true,
          width: '200px',
        },
        {
          name: 'Month',
          key: 'month',
          sortable: true,
        },
        {
          name: 'Hour',
          key: 'hour',
          sortable: true,
        },

        {
          name: 'Batch Number',
          key: 'batch_number',
          sortable: true,
        },
        {
          name: 'Trainee Count',
          key: 'trainee_count',
          sortable: true,
        },
        {
          name: 'Start Date',
          key: 'start_date',
          sortable: true,
          width: '150px',
        },
        {
          name: 'End Date',
          key: 'end_date',
          sortable: true,
          width: '150px',
        },

        // {
        //   name: "Lead Trainer",
        //   key: "lead_trainer",
        //   sortable: true,
        //   width: "170px",
        // },
        // {
        //   name: "Associate Trainer",
        //   key: "associate_trainer",
        //   sortable: true,
        //    width: "170px",
        // },
      ],
      componentKey: 0,
      tableData: [],
      loading: false,
      courseList: [] as any,
      courseInfoData: false,
      showDetails: false,
      instituteList: [],
      tranches_info: [],
    };
  },
  async created() {
    this.moment = moment;
    await this.getTranches();
    await this.getEntityInfos();
    this.emitter.on('batch-list-updated', async () => {
      await this.batchDetails();
    });
  },
  methods: {
    async printPdf() {
      let institute_info_id = this.batch.institute_id;
      if (VueCookieNext.getCookie('_seip_entity_type') == 1011) {
        institute_info_id = VueCookieNext.getCookie('_institute_info_id');
      }
      let formData = new FormData();
      if (this.batch.entity_id && this.batch.tranche_id) {
        formData.set('entity_id', this.batch.entity_id);
        formData.set('tranche_id', this.batch.tranche_id);
        formData.set('course_info_id', this.batch.course_id);
        formData.set('institute_info_id', institute_info_id);
        axios
          .post(
            `${this.VUE_APP_API_URL}/api/batch/running_batch_print`,
            formData
          )
          .then((response) => {
            document.write(response.data);
            window.print();
            location.reload();
            //open the new window and write your HTML to it
          });
      }
    },
    async downloadPdf() {
      ElNotification({
        dangerouslyUseHTMLString: true,
        message:
          '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
      });
      let institute_info_id = this.batch.institute_id;
      if (VueCookieNext.getCookie('_seip_entity_type') == 1011) {
        institute_info_id = VueCookieNext.getCookie('_institute_info_id');
      }
      let formData = new FormData();
      if (this.batch.entity_id && this.batch.tranche_id) {
        formData.set('entity_id', this.batch.entity_id);
        formData.set('tranche_id', this.batch.tranche_id);
        formData.set('course_info_id', this.batch.course_id);
        formData.set('institute_info_id', institute_info_id);
        await ApiService.post('batch/running_batch_pdf', formData)
          .then((response) => {
            Swal.fire({
              title: 'Download Successfull!',
              icon: 'success',
              confirmButtonColor: '#3085d6',
              confirmButtonText: 'View/Download PDF',
            }).then((result) => {
              if (result.isConfirmed) {
                // redirect to new tab

                let path = `${this.VUE_APP_API_URL}/${response.data}`;

                window.open(path, '_blank');
              }
            });
          })
          .catch(({ response }) => {
            console.log(response);
          });
      }
    },
    async exportTrainee() {
      ElNotification({
        dangerouslyUseHTMLString: true,
        message:
          '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
      });
      let institute_info_id = this.batch.institute_id;
      if (VueCookieNext.getCookie('_seip_entity_type') == 1011) {
        institute_info_id = VueCookieNext.getCookie('_institute_info_id');
      }
      let formData = new FormData();
      if (this.batch.entity_id && this.batch.tranche_id) {
        formData.set('entity_id', this.batch.entity_id);
        formData.set('tranche_id', this.batch.tranche_id);
        formData.set('course_info_id', this.batch.course_id);
        formData.set('institute_info_id', institute_info_id);
        axios
          .post(
            `${this.VUE_APP_API_URL}/api/batch/running_batch_export`,
            formData,
            {
              responseType: 'blob',
            }
          )
          .then((response) => {
            const url = URL.createObjectURL(
              new Blob([response.data], {
                type: 'application/vnd.ms-excel',
              })
            );
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'tms-runningCourseBatch-report.xlsx');
            document.body.appendChild(link);
            link.click();
          })
          .catch((response) => {
            console.log(response);
          });
      }
    },
    async getTranches() {
      this.loading = true;
      await ApiService.get(this.VUE_APP_TRANCHE_LIST_API)
        .then((response) => {
          this.tranches_info = response.data.data;
          this.loading = false;
        })
        .catch(({ response }) => {
          console.log(response);
          this.loading = false;
        });
    },
    async courseInfo() {
      this.loading = true;
      let entity_id = this.batch.entity_id;
      let institute_info_id = this.batch.institute_id;
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      if (VueCookieNext.getCookie('_seip_entity_type') == 1011) {
        institute_info_id = VueCookieNext.getCookie('_institute_info_id');
      }
      await ApiService.get(
        this.VUE_APP_COURSE_SEARCH_LIST_API +
        '?entity_id=' +
        entity_id +
        '&tranche=' +
        this.batch.tranche_id +
        '&institute_info_id=' +
        institute_info_id
      )
        .then((response) => {
          console.log(response);
          this.courseList = response.data.data;
          this.courseInfoData = true;
          this.loading = false;
        })
        .catch(({ response }) => {
          console.log(response);
          this.loading = false;
        });
    },
    async getEntityInfos() {
      this.loading = true;
      let entity_id = this.batch.entity_id;
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      await ApiService.get('entity/list?entity_id=' + entity_id)
        .then((response) => {
          console.log(response);
          this.entityInfos = response.data.data;
          this.loading = false;
        })
        .catch(({ response }) => {
          console.log(response);
          this.loading = false;
        });
    },
    async getTrainingInstitute() {
      this.loading = true;
      let institute_info_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') == 1011) {
        institute_info_id = VueCookieNext.getCookie('_institute_info_id');
      }
      await ApiService.get(
        this.VUE_APP_INSTITUTE_LIST_API +
        '?entity_id=' +
        this.batch.entity_id +
        '&institute_info_id=' +
        institute_info_id
      )
        .then((response) => {
          this.instituteList = response.data.data;
          this.loading = false;
        })
        .catch(({ response }) => {
          console.log(response);
          this.loading = false;
        });
    },
    edit(data) {
      this.emitter.emit('batch-update', data);
    },

    Delete(id) {
      Swal.fire({
        title: 'Are you sure you want to delete it?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete!',
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.delete('batch/delete/' + `${id}`)
            .then((response) => {
              this.emitter.emit('batch-list-updated', true);
              this.componentKey += 1;
              Swal.fire('Deleted!', response.data.message, 'success');
            })
            .catch(({ response }) => {
              console.log(response);
            });
        }
      });
    },
    batchDetails() {
      this.loading = true;
      let institute_info_id = this.batch.institute_id;
      if (VueCookieNext.getCookie('_seip_entity_type') == 1011) {
        institute_info_id = VueCookieNext.getCookie('_institute_info_id');
      }
      if (this.batch.entity_id && this.batch.tranche_id) {
        ApiService.get(
          'batch/running_batch?entity_id=' +
          this.batch.entity_id +
          '&tranche_id=' +
          this.batch.tranche_id +
          '&course_info_id=' +
          this.batch.course_id +
          '&institute_info_id=' +
          institute_info_id
        )
          .then((response) => {
            this.componentKey += 1;
            this.tableData = response.data.data;
            this.showDetails = true;
            this.loading = false;
          })
          .catch((response) => {
            console.log(response);
            this.loading = false;
          });
      } else {
        this.loading = false;
        Swal.fire({
          title: 'Warning!',
          html: 'Please select all required fields.',
          icon: 'warning',
        });
      }
    },
  },
});
